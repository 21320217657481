<template>
  <div
    class="home-pannel-wrap"
    :class="{'firm-chat-root-wrapper': isNewWorkbenchMain}">
    <div class="home-pannel-list">
      <div
        class="pannel-item"
        :class="{'pannel-item-narrow': isNewWorkbenchMain && ((isCFUser && index > 5) || (!isCFUser && index > 6))}"
        v-for="(item, index) in cardItems"
        :key="index"
        v-show="item.cnTitle !== 'Csdn职通车' || (item.cnTitle == 'Csdn职通车' && isCFUser)"
        @click.prevent="handleHomeCard(item)">
        <div :class="item.enable?'pannel-card':'pannel-card disabled'">
          <div class="pannel-content">
            <div :class="`pannel-icon icon-${item.enable?item.icon:item.icon+' disabled'}`"></div>
            <div class="pannel-text">
                <p>
                    <span class="cn-title">{{item.cnTitle}}</span>
                    <br v-if="index > 4">
                    <span class="en-title">{{item.enTitle}}</span>
                </p>
                <p class="pannel-desc" v-if="item.desc && (!isCFUser || (isCFUser && item.cnTitle !== '组织架构'))" v-html="item.desc"></p>
                <p v-if="item.cnTitle == '流程管理' && isCFUser" class="panel-point-tip">变更推荐状态每次可得5积分哦</p>
            </div>
          </div>
          <div class="pannel-notice" v-if="(isNewWorkbenchMain && index <= 3) || !isNewWorkbenchMain">
            <div class="notice-wrap" v-if="item.noticeData">
              <el-carousel
                height="100%"
                :autoplay="true"
                indicator-position="none"
                arrow="never"
                direction="vertical"
                :loop="true">
                <template v-for="notice in item.noticeData">
                  <el-carousel-item
                    :key="notice.reminderType"
                    v-if="notice.totalStatisticsCount>0">
                    <p class="notice-item" @click.stop="noticeLink(notice.reminderType,item)">
                      <i class="icon-font icon-notice"></i>您有<span
                        class="orange-text"
                        >{{ notice.totalStatisticsCount }}</span
                      >位{{
                        notice.reminderType === 1
                          ? "候选人未处理"
                          : "候选人的推荐已接受"
                      }}，<a href="javascript:;" class="orange-text">{{notice.reminderType === 1
                          ? "处理看看"
                          : "去更新状态"}}</a>吧
                    </p>
                  </el-carousel-item>
                </template>
              </el-carousel>
            </div>
          </div>
          <span class="conner-mark" v-if="!item.enable">敬请期待</span>
          <span class="conner-mark conner-mark-new" v-if="item.enable && item.isNew">New</span>
        </div>
      </div>
    </div>
    <div class="firm-chat-room-wrapper" v-if="isNewWorkbenchMain">
      <firm-chat-room ref="firmChatRoom"></firm-chat-room>
    </div>
    <!-- <div class="trigger-new-workbench-btn" @click="triggerNewWorkbench">
      {{isNewWorkbenchMain ? '切换旧版工作台' : '切换新版工作台'}}
    </div> -->
    <!-- <dialog-guide :contentImg="hpGuideImg" :localStorageKey="localStorageKey" :closeCallback="handleGuide"></dialog-guide> -->
    <image-dialog
      width="500px"
      height="880px"
      :imageSrc="jdtQcodeImage"
      imageAlt="Csdn职通车二维码"
      v-if="isCFUser && jdtDialogVisible"
      @hideDialog="hideJdtDialog"
    ></image-dialog>
  </div>
</template>
<script>
import { floatingList as floatingListUrl } from "#/js/config/api.json";
import { mapActions } from 'vuex';
// import DialogGuide from '@src/component/noobGuide/dialogGuide.vue';
import FirmChatRoom from "#/views/work-table-home/component/firm-chat-room.vue";
import ImageDialog from '#/component/activity/imageDialog.vue'

export default {
  name: "WorkTableHome",
  components:{
    // DialogGuide,
    FirmChatRoom,
    ImageDialog
  },
  data() {
    return {
      /**
       * @params
       * enable: 菜单是否有效，必填
       * enTitle: 标签页的小标题，必填
       * cnTitle: 标签页的主标题，必填
       * isNew: 是否需要展示new的tip
       * isLink: 是否是单纯的链接跳转
       * noticeData: 小提示文案
       * path: 路径，如果菜单有效则为必填
       * icon: 图标名，必填。如果新增则需要配置
       * desc: 菜单描述文案
       * aliveComponent: 菜单对应的组件名，如果是标签页的菜单则为必填
       */
      cardItems:[
          {
              enable: true,
              isNew: false,
              enTitle:'Talent Pool',
              cnTitle:'我的人才池',
              noticeData:null,
              path:'/talentPool',
              icon:'talent-pool',
              desc:'自动收集、分类系统中你<span>接触过的、感兴趣的</span>人才',
              aliveComponent: 'TalentPool'
          },
          {
              enable: true,
              isNew: false,
              enTitle:'Tracking List',
              cnTitle:'人才跟进',
              noticeData:null,
              path:'/trackingList',
              icon:'tracking-list',
              desc:'管理当前<span>有求职意愿</span>的可能<span>被推荐出去</span>的候选人',
              aliveComponent: 'TrackingList'
          },
          {
              enable: true,
              isNew: false,
              enTitle:'Floating List',
              cnTitle:'流程管理',
              noticeData:null,
              path:'/floatingList/myRecommend',
              icon:'floating-list',
              desc:'快速<span>变更查看</span>已推荐的候选人的最新状态',
              aliveComponent: 'FloatingListMyRecommend'
          },
          {
              enable: true,
              isNew: false,
              enTitle:'Orders',
              cnTitle:'我的订单',
              noticeData:null,
              path:"/myOrders/grabbedJob",
              icon:'orders',
              desc:'及时查看<span>创建、抢单职位</span>的推荐记录。帮助复盘客户整体交付情况',
              aliveComponent: 'MyOrdersGrabbedJob'
          },
          // {
          //     enable: false,
          //     isNew: false,
          //     enTitle:'To-do List',
          //     cnTitle:'待办事项',
          //     noticeData:null,
          //     path: null,
          //     icon:'todo-list'
          // },
          {
              enable: true,
              isNew: false,
              isLink: true,
              enTitle:'Organization',
              cnTitle:'组织架构',
              noticeData: null,
              // path: '/Headhunting/MyCompany.html#/customerList',
              path: '/#/customerList',
              desc:'查看公司组织架构，了解部门更多信息',
              icon:'department'
          },
        //   {
        //       enable: true,
        //       isNew: false,
        //       isLink: false,
        //       enTitle: '',
        //       cnTitle: 'Csdn职通车',
        //       noticeData: null,
        //       // path: '/Headhunting/MyCompany.html#/jdt',
        //       path: '/#/jdt',
        //       desc: '',
        //       icon: 'jdt'
        //   },
          {
              enable: true,
              isNew: true,
              isLink: true,
              enTitle:'Projects',
              cnTitle:'项目',
              noticeData:null,
              // path: '/Headhunting/MyCompany.html#/project',
              path: '/#/project',
              icon:'projects'
          },
        //   {
        //       enable: false,
        //       isNew: false,
        //       enTitle:'Likes／Wants',
        //       cnTitle:'猜你想要',
        //       noticeData:null,
        //       path:null,
        //       icon:'likes-wants'
        //   },
          // {
          //     enable: false,
          //     isNew: false,
          //     enTitle:'Communication',
          //     cnTitle:'我的沟通',
          //     noticeData:null,
          //     path:null,
          //     icon:'communication'
          // },
          // {
          //     enable: false,
          //     isNew: false,
          //     enTitle:'Targets',
          //     cnTitle:'目标公司',
          //     noticeData:null,
          //     path:null,
          //     icon:'targets'
          // }

      ],
      isNewWorkbenchMain: true,
      jdtDialogVisible: false
      // localStorageKey:'homePageGuide',
      // hpGuideImg: require('#/assets/images/guide/img_homepage_guide.png')
    }
  },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isFinancial () {
            return this.userInfo.privilegeCodeList?.includes('Financial');
        },
        isCFUser() {
            return this.$store.state.user.userInfo.isCFUser
        },
        jdtQcodeImage() {
        return `/Sharing/${this.$store.state.user.userInfo.id}/JDTQRCode`
        }
    },
  mounted() {
    // this.isNewWorkbenchMain = !localStorage.getItem('isNewWorkbenchMain') || (localStorage.getItem('isNewWorkbenchMain') && localStorage.getItem('isNewWorkbenchMain') === 'true') ? true : false;
    this.getFloatingListNotice();
  },
  methods: {
    getFloatingListNotice() {
      let url = floatingListUrl.floating_list_notice;
      _request({
        method: 'GET',
        url: url,
        data: {
          hasFilteredWords: false,
          isContainRemovable: false,
          hasSubReminders: false
        }
      }).then(response => {
          this.cardItems[2].noticeData = response;//floating list的提示数据
      })
    },
    routerSwitch(path) {
      this.$router.push({ path: path });
    },
    handleHomeCard(cardItem){
        if(cardItem.cnTitle === 'Csdn职通车') {
            //   this.jdtDialogVisible = true;
            return window.open(cardItem.path);
        }
        if(!cardItem.path){
            return;
        }
        if(cardItem.isLink) {
            if(cardItem.enTitle == 'Projects') {
                window.open(cardItem.path);
                return;
            }else {
                location.href = cardItem.path;
                return;
            }
        }
        let item = {
            cnTitle: cardItem.cnTitle,
            enTitle: cardItem.enTitle,
            aliveComponent: cardItem.aliveComponent,
            path: cardItem.path,
            isActive: true
        },pathname = '';

        switch(cardItem.path){
            case '/trackingList':
                pathname = 'TrackingList';
                break;
            case '/talentPool':
                pathname = 'TalentPool';
                break;
            case '/floatingList/myRecommend':
                pathname = 'FloatingList';
                console.log(this.isFinancial);
                if(this.isFinancial) {
                    item.path = '/floatingList/apply'
                }
                break;
            case '/myOrders/grabbedJob':
                pathname = 'MyOrders';
                break;
            default:
                pathname = '';
                break;
        }
        if(pathname){
            _tracker.track('PageViewCount', JSON.stringify({
                page: cardItem.path,
                pageName: pathname,
                from: 'homePage'
            }));
        }

        this.invokePushTabItems(item);
        this.routerSwitch(item.path);
    },
    noticeLink(reminderType,item){
        //用于消息跳转
        let path = '/floatingList';
        let query = {};
        switch (reminderType) {
            case 1:
                query={
                    status: 0
                }
                break;
            case 3:
                query={
                    status: 1
                }
                path = '/floatingList/myRecommend';
                break;
            default:
                break;
        }
        this.invokePushTabItems(item);
        this.invokeSetNoticeVisible(false);
        this.$router.push({ path: path,query:query });
    },
    hideJdtDialog() {
      this.jdtDialogVisible = false;
    },
    // handleGuide(){
    //     localStorage.setItem(this.localStorageKey,true);
    // },
    // triggerNewWorkbench() {
    //   this.isNewWorkbenchMain = !this.isNewWorkbenchMain;
    //   localStorage.setItem('isNewWorkbenchMain', this.isNewWorkbenchMain);
    //   _tracker.track('TriggerWorkbench', JSON.stringify({
    //     triggerWorkbench: this.isNewWorkbenchMain ? 'New' : 'Old'
    //   }));
    // },
    ...mapActions('workbench',[
        'invokePushTabItems'
    ]),
    ...mapActions('notice',[
        'invokeSetNoticeVisible'
    ])
  }

};
</script>
<style lang="scss" scoped>
.home-pannel-wrap {
  position: relative;
  padding: 10px 45px;
  height: 100%;
  padding-bottom: 50px;
  background: url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;
  .home-pannel-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  &.firm-chat-root-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .home-pannel-list {
      width: 66.67%;
    }
    .pannel-item {
      width: 50%;
    }
  }
  .firm-chat-room-wrapper {
    min-width: 380px;
    width: 33.33%;
    padding: 15px;
  }
  a{
    outline: none;
    &:hover,&:focus{
      color: #fff;
    }
  }
  .pannel-item {
    width: 33.3%;
    height: 33.3%;
    padding: 15px;
    min-height: 180px;
    .pannel-card {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #fff;
      transition: all 0.6s;
      cursor: pointer;
      height: 100%;
      &:hover {
        box-shadow: 0 0 8px 6px rgba(0, 0, 0, 0.1);
        transition: all 0.6s;
        .cn-title {
          font-weight: bold;
        }
      }
      &.disabled{
          cursor: unset;
      }
      .pannel-notice {
        height: 44px;
        line-height: 44px;
        .icon-notice {
          vertical-align: middle;
          color: $primary;
          margin-right: 8px ;
        }
        .notice-wrap {
          height: 100%;
          font-size: 16px;
          color: #666;
          .orange-text {
            color: #f47b5d;
          }
          .el-carousel {
            height: 100%;
          }
          .notice-item{
            background: rgba(56, 188, 157, 0.2);
            padding: 0 24px;
          }
        }
      }
      .pannel-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
        padding-left: 3.8vw;
        .pannel-icon {
          width: 5.8vw;
          height: 5.8vw;
          border-radius: 50%;
          overflow: hidden;
          background: rgba(56, 188, 157, 0.1);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          &.icon-talent-pool{
              background-image: url('~@src/assets/images/home/img-talent-pool.png');
          }
          &.icon-tracking-list{
              background-image: url('~@src/assets/images/home/img-tracking-list.png');
          }
          &.icon-floating-list{
              background-image: url('~@src/assets/images/home/img-floating-list.png');
          }
          &.icon-todo-list{
              background-image: url('~@src/assets/images/home/img-todo-list.png');
              &.disabled{
                  background-image: url('~@src/assets/images/home/img_todolist_grey.png')
              }
          }
          &.icon-likes-wants{
              background-image: url('~@src/assets/images/home/img-likes-wants.png');
              &.disabled{
                  background-image: url('~@src/assets/images/home/img_likes_grey.png')
              }
          }
          &.icon-orders{
              background-image: url('~@src/assets/images/home/img-orders.png');
          }
          &.icon-projects{
              background-image: url('~@src/assets/images/home/img-projects.png');
               &.disabled{
                  background-image: url('~@src/assets/images/home/img_projects_grey.png')
              }
          }
          &.icon-communication{
              background-image: url('~@src/assets/images/home/img-communication.png');
              &.disabled{
                  background-image: url('~@src/assets/images/home/img_communication_grey.png')
              }
          }
          &.icon-department,
          &.icon-targets {
              background-image: url('~@src/assets/images/home/img-targets.png');
              &.disabled{
                  background-image: url('~@src/assets/images/home/img_targets_grey.png')
              }
          }
          &.icon-jdt {
              background-image: url('~@src/assets/images/home/img-jdt.png');
              &.disabled{
                  background-image: url('~@src/assets/images/home/img-jdt.png')
              }
          }
        }
        .pannel-text {
          flex-grow: 1;
          margin-left: 20px;
          .en-title {
            //font-size: 18px;
            font-size: 1vw;
            color: #666;
          }
          .cn-title {
            //font-size: 24px;
            font-size: 1.25vw;
            color: #19302b;
            margin-top: 10px;
            margin-right: 10px;
          }
          .pannel-desc{
              width: 11.5vw;
              font-size: 0.9vw;
              line-height: 1.4;
              color: #666;
              animation: shine 1s ease-in-out;
              /deep/ span{
                  color: $primary;
              }

          }
          .panel-point-tip {
            font-size: 0.8vw;
            color: #EE6E4F;
          }
        }
      }
      .conner-mark {
        position: absolute;
        display: inline-block;
        width: 64px;
        height: 30px;
        line-height: 30px;
        background: rgba(56, 188, 157, 0.2);
        font-size: 14px;
        color: #38bc9d;
        text-align: center;
        left: 10px;
        top: 0;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        &.conner-mark-new {
          background: rgba(255, 101, 100, 0.2);
          color: #ff6564;
        }
      }
    }
    &.pannel-item-narrow {
      width: 25%;
      .pannel-content {
        padding-left: 12px;
        padding-right: 8px;
        .pannel-icon {
          width: 32%;
          background-size: contain;
          background-color: inherit;
        }
        .pannel-text {
          width: 62%;
          overflow: hidden;
          & > p {
            margin-bottom: 0;
            word-break: break-word;
          }
          .cn-title {
            font-size: 1vw;
          }
          .en-title {
            font-size: 14px;
          }
        }
      }
    }
    &.is-jdt-pannel-item {
      z-index: 11;
      position: relative;
      &:hover {
        .pannel-card {
          cursor: default;
        }
        .cn-title {
          font-weight: normal;
        }
      }
    }
  }
  .trigger-new-workbench-btn {
    position: absolute;
    bottom: 16px;
    right: 60px;
    width: 140px;
    height: 32px;
    line-height: 32px;
    background-color: $primary;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    text-align: center;
    &:hover, &:focus {
      background-color: #48CAAC;
    }
    &:active {
      background-color: #36B495;
    }
  }
}
@media screen and (max-width: 1440px) {
  .home-pannel-wrap {
    padding: 0 22px 50px;
    .firm-chat-room-wrapper {
      padding: 8px;
    }
    .pannel-item {
      padding: 8px;
      .pannel-card {
        .pannel-notice {
          height: 30px;
          line-height: 30px;
          .notice-wrap {
            font-size: 12px;
            .notice-item{
                padding: 0 12px;
            }
          }
        }
        .pannel-content {
          .pannel-icon {
            width: 58px;
            height: 58px;
          }
          .pannel-text {
            margin-left: 12px;
            .en-title {
              font-size: 14px;
            }
            .cn-title {
              font-size: 18px;
              margin-top: 4px;
            }
          }
        }
        .conner-mark {
          width: 54px;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
        }
      }
      &.pannel-item-narrow {
        .pannel-card {
          .pannel-content {
            .pannel-icon {
              width: 30%;
            }
            .pannel-text {
              .en-title {
                font-size: 12px;
              }
              .cn-title {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .trigger-new-workbench-btn {
      right: 30px;
      bottom: 10px;
      width: 100px;
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 1600px) {
  .home-pannel-wrap {
    .pannel-item {
      .pannel-card {
        .pannel-notice {
          .notice-wrap {
            font-size: 12px;
            .notice-item{
                padding: 0 10px;
            }
          }
        }
      }
    }
  }
}
@keyframes shine {
    0%{
        opacity: 0;
    }
    33%{
        opacity: 1;
    }
    66%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
</style>

<style lang="scss">
@keyframes flicker {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>