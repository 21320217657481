<template>
  <el-dialog
    :width="width"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="image-dialog">
    <div class="post-container" :style="{'width': width, 'height': height}">
        <img :src="imageSrc" :alt="imageAlt"/>
        <slot></slot>
        <span class="close-post-btn" @click="hideDialog"></span>
    </div>
  </el-dialog>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: '760px'
        },
        height: {
            type: String,
            default: '520px'
        },
        imageSrc: String,
        imageAlt: ""
    },
    data() {
        return {
            dialogVisible: true
        }
    },
    methods: {
        hideDialog() {
            this.dialogVisible = false;
            if(document.querySelector('#floatOption')){
                document.querySelector('#floatOption').style.zIndex = "";
            }
            this.$emit("hideDialog");
        }
    }
}
</script>

<style lang="scss" scope>
    .image-dialog.el-dialog {
        transform: unset !important;
        position: relative !important;
        top: unset !important;
        left: unset !important;
        margin-top: 30px !important;
        .el-dialog__header {
            display: none;
        }
        .el-dialog__body {
            padding: 0;
        }
        .post-container {
            width: 760px;
            height: 520px;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                border-radius: 6px;
            }
            .close-post-btn {
                width: 18px;
                height: 18px;
                color: #fff;
                border-radius: 9px;
                border: 1px solid #fff;
                position: absolute;
                right: -24px;
                top: -24px;
                line-height: 18px;
                cursor: pointer;
                &::before {
                    content: "×";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                &:hover {
                    color: #fff;
                    background-color: #e95352;
                    border-color: #e95352;
                }
            }
        }
    }
    // @media only screen and (max-height: 736px) {
    //     .el-dialog__wrapper .image-dialog.el-dialog {
    //         transform: translate(-50%, -50%);
    //     }
    // }
    // @media only screen and (max-height: 590px) {
    //     .el-dialog__wrapper .image-dialog.el-dialog {
    //         transform: translate(-50%, -40%);
    //     }
    // }
</style>