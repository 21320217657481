var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: _vm.width,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false,
        "custom-class": "image-dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "post-container",
          style: { width: _vm.width, height: _vm.height },
        },
        [
          _c("img", { attrs: { src: _vm.imageSrc, alt: _vm.imageAlt } }),
          _vm._t("default"),
          _c("span", {
            staticClass: "close-post-btn",
            on: { click: _vm.hideDialog },
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }