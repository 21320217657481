var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "firm-chat-room" },
    [
      _c("div", { staticClass: "firm-title" }, [
        _c("img", {
          staticClass: "firm-chat-avatar",
          attrs: { src: _vm.groupImageSrc },
        }),
        _vm._v(
          "\n        " +
            _vm._s(_vm.userInfo.firmShortName) +
            _vm._s(
              _vm.currentConversation.groupProfile.memberNum
                ? `(${_vm.currentConversation.groupProfile.memberNum})`
                : ""
            ) +
            "\n    "
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.messageLoading,
              expression: "messageLoading",
            },
          ],
          staticClass: "firm-chat-list",
        },
        [
          _c(
            "div",
            {
              ref: "message-list",
              staticClass: "message-list",
              on: { scroll: _vm.onScroll },
            },
            [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isCompleted,
                    expression: "!isCompleted",
                  },
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.messagePagingLoading,
                    expression: "messagePagingLoading",
                  },
                ],
                staticClass: "more",
              }),
              _vm.isCompleted
                ? _c("div", { staticClass: "no-more" }, [
                    _vm._v("\n                没有更多了\n            "),
                  ])
                : _vm._e(),
              _vm._l(_vm.currentMessageList, function (message, index) {
                return _c("message-item", {
                  key: index,
                  attrs: {
                    message: message,
                    deleteMemberNick: _vm.deleteMemberNick,
                    memberList: _vm.memberList,
                    "current-conversation": _vm.currentConversation,
                    "current-user-profile": _vm.currentUserProfile,
                    isInner: true,
                    isWorkBenchMain: true,
                  },
                })
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hasNewMessage && _vm.isShowScrollButtomTips,
                  expression: "!hasNewMessage && isShowScrollButtomTips",
                },
              ],
              staticClass: "to-bottom-tip",
              on: { click: _vm.scrollMessageListToButtom },
            },
            [_vm._v("\n            回到初始位置\n        ")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasNewMessage && _vm.distanceToBottom > 10,
                  expression: "hasNewMessage && (distanceToBottom > 10)",
                },
              ],
              staticClass: "at-me-tips",
              on: { click: _vm.scrollMessageListToButtom },
            },
            [_vm._v("\n            您有新消息\n        ")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isAtMe,
                  expression: "isAtMe",
                },
              ],
              staticClass: "at-me-tips",
              on: { click: _vm.scrollToAtMe },
            },
            [
              _vm._v("\n            有人提到我\n            "),
              _c("i", {
                staticClass: "el-icon-close",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.isAtMe = false
                  },
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isAtMe && _vm.unreadCount > 15,
                  expression: "!isAtMe && unreadCount > 15",
                },
              ],
              staticClass: "unread-tips",
              on: {
                click: function ($event) {
                  return _vm.getMessageList(
                    _vm.unreadCount - _vm.currentMessageList.length
                  )
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-thumb" }),
              _vm._v(
                "\n            " +
                  _vm._s(_vm.unreadCount) +
                  "条新消息\n            "
              ),
              _c("i", {
                staticClass: "el-icon-close",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.unreadCount = 0
                  },
                },
              }),
            ]
          ),
        ]
      ),
      _c("message-send-box", {
        ref: "messageSendBox",
        attrs: {
          userId: _vm.userInfo.unionId,
          currentConversation: _vm.currentConversation,
          memberList: _vm.memberList,
          isWorkBenchMain: true,
          toAccount: _vm.currentConversation.groupProfile.groupID,
        },
        on: { sendMessage: _vm.handleSendMessage },
      }),
      _c("image-previewer", {
        ref: "image-previewer",
        attrs: { imgUrlList: _vm.imgUrlList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }