<template>
    <div class="firm-chat-room">
        <div class="firm-title">
            <img class="firm-chat-avatar" :src="groupImageSrc">
            {{userInfo.firmShortName}}{{currentConversation.groupProfile.memberNum ? `(${currentConversation.groupProfile.memberNum})` : ""}}
        </div>
        <div
            v-loading="messageLoading"
            class="firm-chat-list">
            <div
                ref="message-list"
                class="message-list"
                @scroll="onScroll">
                <div
                    class="more"
                    v-show="!isCompleted"
                    v-loading="messagePagingLoading"
                ></div>
                <div
                    class="no-more"
                    v-if="isCompleted">
                    没有更多了
                </div>
                <message-item
                    v-for="(message, index) in currentMessageList" 
                    :key="index"
                    :message="message"
                    :deleteMemberNick="deleteMemberNick"
                    :memberList="memberList"
                    :current-conversation="currentConversation"
                    :current-user-profile="currentUserProfile"
                    :isInner="true"
                    :isWorkBenchMain="true"
                ></message-item>
            </div>
            <div
                class="to-bottom-tip"
                v-show="!hasNewMessage && isShowScrollButtomTips"
                @click="scrollMessageListToButtom">
                回到初始位置
            </div>
            <div
                v-show="hasNewMessage && (distanceToBottom > 10)"
                class="at-me-tips"
                @click="scrollMessageListToButtom">
                您有新消息
            </div>
            <div
                v-show="isAtMe"
                class="at-me-tips"
                @click="scrollToAtMe">
                有人提到我
                <i class="el-icon-close" @click.stop="isAtMe = false;"></i>
            </div>
            <div
                v-show="!isAtMe && unreadCount > 15"
                class="unread-tips"
                @click="getMessageList(unreadCount - currentMessageList.length)">
                <i class="el-icon-thumb"></i>
                {{unreadCount}}条新消息
                <i class="el-icon-close" @click.stop="unreadCount = 0;"></i>
            </div>
        </div>
        <message-send-box
            ref="messageSendBox"
            :userId="userInfo.unionId"
            :currentConversation="currentConversation"
            :memberList="memberList"
            :isWorkBenchMain="true"
            :toAccount="currentConversation.groupProfile.groupID"
            @sendMessage="handleSendMessage"
        ></message-send-box>
        <image-previewer ref="image-previewer" :imgUrlList="imgUrlList" />
    </div>
</template>

<script>
import MessageItem from '#/component/page/open-instant-message/message/message-item.vue';
import MessageSendBox from '#/component/page/open-instant-message/message/message-send-box.vue';
import { convertSdkMsgToLbdMsg, convertMessage } from '#/component/page/open-instant-message/utils/convertIM.js';
import { avatarHost } from '#/component/page/open-instant-message/utils/avatarHost.js';
import imService from '#/js/service/openIMService.js';
import ImagePreviewer from '#/component/page/open-instant-message/message/image-previewer.vue';
export default {
    componentName: "FrimChatRoom",
    components: {
        MessageItem,
        MessageSendBox,
        ImagePreviewer
    },
    data() {
        return {
            messageLoading: false,
            isCompleted: false,
            messagePagingLoading: false,
            currentMessageList: [],
            memberList: [],
            currentConversation: {
                conversationID: `GROUP`,
                type: "GROUP",
                groupProfile: {
                    groupID: ""
                }
            },
            currentUserProfile: {
                avatar: "",
                userID: ""
            },
            hasNewMessage: false,
            isShowScrollButtomTips: false,
            distanceToBottom: 0,
            isAtMe: false,
            atMeMsgStart: -1,
            nextReqMessageID: 0,
            pageTabVisible: document.visibilityState == 'visible' ? true : false,
            preScrollHeight: 0
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        unreadCount: {
            get() {
                return this.$store.state.workbench.firmGroup.unreadCount
            },
            set(val) {
                this.$store.dispatch('workbench/invokeSetFirmGroupInfo', {
                    unreadCount: val
                });
                if(val === 0) {
                    this.handleSetMessageRead();
                }
            }
        },
        newMessageList() {
            return this.$store.state.workbench.firmGroup.newMessageList;
        },
        deleteMemberNick() {
            return this.$store.state.workbench.firmGroup.deleteMemberNick;
        },
        imgUrlList() {
            return this.currentMessageList
                .filter(message => message.msgType === TIM.TYPES.MSG_IMAGE && !message.isRevoked) // 筛选出没有撤回并且类型是图片类型的消息
                .map(message => message.msgContent.imageInfoArray[0].url)
        },
        groupImageSrc() {
            return `${avatarHost()}/group/${this.userInfo.tbdFirmId}`;
        }
    },
    watch: {
        newMessageList(val) {
            if(val.length > 0) {
                this.hasNewMessage = true;
                let lbdMsgList = [];
                val.forEach(message => {
                    if(message.type === TIM.TYPES.MSG_CUSTOM && !message.payload.data) {
                        try {
                            message.payload.extension = JSON.parse(message.payload.extension);
                        } catch {
                            message.payload.extension = message.payload.extension;
                        }
                        message.payload.ext = message.payload.extension;
                    }
                    if(message.type === TIM.TYPES.MSG_CUSTOM && message.payload.data) {
                        try {
                            message.payload.ext = JSON.parse(message.payload.data);
                            message.payload.data = null;
                        } catch(err) {
                            message.payload.ext = message.payload.data;
                            message.payload.data = null;
                        }
                    }
                    let LbdMsg = convertSdkMsgToLbdMsg(message);
                    LbdMsg.fromAccountName = message.nick;
                    if(LbdMsg.msgType === TIM.TYPES.MSG_GRP_TIP) {
                        if(LbdMsg.msgContent.operationType === TIM.TYPES.GRP_TIP_GRP_PROFILE_UPDATED ||
                            LbdMsg.msgContent.operationType === TIM.TYPES.GRP_TIP_MBR_QUIT ||
                            LbdMsg.msgContent.operationType === TIM.TYPES.GRP_TIP_MBR_KICKED_OUT ||
                            LbdMsg.msgContent.operationType === TIM.TYPES.GRP_TIP_MBR_JOIN) {
                            lbdMsgList.push(LbdMsg);
                        }
                    } else {
                        lbdMsgList.push(LbdMsg);
                    }
                })
                this.currentMessageList = [...this.currentMessageList, ...lbdMsgList];
                this.checkIsAtMe();
                this.handleGroupTip();
                this.$nextTick(() => {
                    if(this.distanceToBottom < 10) {
                        this.keepMessageListOnButtom();
                    }
                })
                this.$store.dispatch('workbench/invokeSetFirmGroupInfo', {
                    newMessageList: []
                });
            }
        },
        unreadCount: {
            handler: function(val) {
                if(val > 0) {
                    this.checkIsAtMe();
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.$on('image-loaded', (json) => {
            this.keepMessageListOnButtom();
        });
        this.$on('image-preview', (json) => {
            this.$refs['image-previewer'].handlePreview(json);
        });
        this.init();
    },
    methods: {
        init() {
            this.currentUserProfile = {
                avatar: `${avatarHost()}/user/${this.userInfo.unionId}`
            };
            this.currentConversation = {
                conversationID: `GROUP_${this.userInfo.tbdFirmId}`,
                type: "GROUP",
                groupProfile: {
                    groupID: this.userInfo.tbdFirmId
                }
            }
            this.nextReqMessageID = '';
            this.getMessageList();
            if(openIM) {
                openIM.getLoginStatus().then(res => {
                    // 101:登录成功 102:登陆中 103:登录失败 201:登出
                    if (res.data == 101) {
                        this.getFirmProfile();
                    } else {
                        this.getOpenIMStatus();
                    }
                }).catch(err => {
                    this.getOpenIMStatus();
                    console.log(err);
                })
                // tim.on(TIM.EVENT.SDK_READY, this.getFirmProfile, this)
            } else  {
                this.getOpenIMStatus();
            }
            document.addEventListener('visibilitychange', () => {
                if(document.visibilityState !== 'visible') {
                    this.pageTabVisible = false;
                }
            });
        },
        getOpenIMStatus() {
            let groupProfileInterval = setInterval(() => {
                console.log('open-im-interval');
                if(openIM) {
                    openIM.getLoginStatus().then(res => {
                        // 101:登录成功 102:登陆中 103:登录失败 201:登出
                        if (res.data == 101) {
                            clearInterval(groupProfileInterval);
                            this.getFirmProfile();
                        }
                    }).catch(err => {
                        console.log(err);
                    })
                    // tim.on(TIM.EVENT.SDK_READY, this.getFirmProfile, this)
                }
            }, 1000);
        },
        getFirmProfile() {
            openIM.getGroupsInfo([this.userInfo.tbdFirmId]).then(({ data })=>{
                if (data && data != '[]') {
                    let group = JSON.parse(data)[0];
                    this.currentConversation.groupProfile = {
                        memberNum: group.memberCount,
                        groupID: group.groupID,
                        groupName: group.groupName
                    };

                    this.getMemberList();
                }
            }).catch(err => {
                console.log(err);
            })
            // tim.getGroupProfile({ groupID: this.userInfo.tbdFirmId }).then((imResponse) => {
            //     this.currentConversation.groupProfile = imResponse.data.group;
            //     this.getMemberList();
            // }).catch((imError) => {
            //     console.warn('getGroupProfile error:', imError);
            // });
        },
        getMessageList(unreadCount) {
            if(!this.currentMessageList.length) {
                this.messageLoading = true;
            } else {
                this.messagePagingLoading = true;
            }
            let param = {
                count: unreadCount ? unreadCount : 15,
                flipOverType: 0,
                type: 1,
                nextReqMessageId: this.nextReqMessageID,
                groupId: this.currentConversation.groupProfile.groupID
            }
            imService.getMessageList(param)
            .then(res =>{
                this.isCompleted = res.isCompleted;
                this.nextReqMessageID = res.nextReqMessageId || 0;

                if(res.messageList) {
                    res.messageList.forEach((message, index) => {
                        if(message.msgType === "TIMCustomElem" && !message.msgContent.data) {
                            try {
                                message.msgContent.ext = JSON.parse(message.msgContent.ext);
                            } catch(err) {
                                message.msgContent.ext = message.msgContent.ext;
                            }
                        }
                    })
                    this.currentMessageList = [...res.messageList, ...this.currentMessageList];

                    //翻页定位
                    if(!unreadCount) {
                        this.$nextTick(() =>{
                            let index = res.messageList.length - 1;
                            this.atMeMsgStart += res.messageList.length;
                            this.scrollToMessageByIndex(index);
                        })
                        this.checkIsAtMe();
                    }
                }
                if(this.messageLoading) {
                    this.messageLoading = false;
                } else {
                    this.messagePagingLoading = false;
                }
                if(unreadCount) {
                    if(this.isAtMe) {
                        this.$nextTick(() => {
                            this.scrollToAtMe();
                        })
                    } else {
                        let messageListNode = this.$refs['message-list'];
                        if(messageListNode) {
                            messageListNode.getElementsByClassName('message-wrapper')[0].scrollIntoView();
                        }
                    }
                    this.unreadCount = 0;
                }
            }).catch(err =>{
                this.messageLoading = false;
                this.messagePagingLoading = false;
                if(err.errorMessage && err.errorMessage !== '查询类型不能为空') {
                    shortTips(err.errorMessage)
                }
            })
        },
        async getMemberList() {
            let group = this.currentConversation.groupProfile,
                memberList = [];
            let count = group.memberNum;
            for(let i = 0; i < Math.ceil(count / 100); i++) {
                await openIM.getGroupMemberList({
                    groupID: group.groupID,
                    filter: 0, // 1普通成员, 2群主，3管理员
                    count: 100,
                    offset: 100 * i
                }).then((list) => {
                    list = JSON.parse(list.data);

                    list = list.map(item => {
                        return {
                            userID: item.userID,
                            nick: item.nickname,
                            avatar: item.faceURL,
                            role: item.roleLevel === 2 ? 'Owner' : ''
                        }
                    })

                    memberList = memberList.concat(list);
                    if(i === Math.ceil(count / 100) - 1) {
                        memberList.unshift({
                            userID: "",
                            nick: "所有人",
                            avatar: require("@src/assets/images/default-user.png")
                        });
                        this.memberList = memberList;
                    }
                }).catch(err => {
                    console.log("getGroupMemberListError: ", err);
                })
            }
        },
        onScroll() {
            let messageListNode = this.$refs['message-list']
            if (!messageListNode) {
                return
            }
            let msgScrollTop = messageListNode.scrollTop,
                msgScrollHeight = messageListNode.scrollHeight,
                msgOffsetHeight = messageListNode.offsetHeight,
                msgClientHeight = messageListNode.clientHeight;
            //总高度-可视高度-滚动条高度
            this.distanceToBottom = msgScrollHeight - msgOffsetHeight - msgScrollTop;
            if(this.distanceToBottom < 10) {
                this.hasNewMessage = false;
                if(this.unreadCount > 0 && this.unreadCount <= 15 && this.pageTabVisible) {
                    this.unreadCount = 0;
                } else if(!this.pageTabVisible) {
                    this.pageTabVisible = true;
                }
            }
            if(msgScrollHeight - msgScrollTop > 1800) {
                this.isShowScrollButtomTips = true;
            } else if (msgScrollHeight - msgScrollTop <= (msgClientHeight * 2)) {
                this.isShowScrollButtomTips = false;
            }
            if(!this.isCompleted && !this.messageLoading && !this.messagePagingLoading && msgScrollTop < 20) {
                this.getMessageList();
            }
        },
        scrollToMessageByIndex(index) {
            let messageListNode = this.$refs['message-list'];
            if(messageListNode  && messageListNode.getElementsByClassName('message-wrapper')[index] && messageListNode.getElementsByClassName('message-wrapper')[index].scrollIntoView) {
                messageListNode.getElementsByClassName('message-wrapper')[index].scrollIntoView();
            }
        },
        // 直接滚到底部
        scrollMessageListToButtom() {
            this.hasNewMessage = false;
            this.$nextTick(() => {
                let messageListNode = this.$refs['message-list']
                if (!messageListNode) {
                    return
                }
                messageListNode.scrollTop = messageListNode.scrollHeight;
                this.isShowScrollButtomTips = false;
            })
        },
        scrollToAtMe() {
            if(this.currentMessageList.length < this.unreadCount) {
                this.getMessageList(this.unreadCount - this.currentMessageList.length);
            } else {
                if(this.currentMessageList.length === 15 && this.unreadCount !== 0) {
                    this.atMeMsgStart = this.currentMessageList.length - this.unreadCount;
                }
                let start = this.atMeMsgStart !== -1 ? this.atMeMsgStart : 0;
                let newMessageList = this.currentMessageList.slice(start, this.currentMessageList.length);
                let atMeMessageIndex = newMessageList.findIndex(message => {
                    return message.msgContent.ext && message.msgContent.ext.type === 'atMessage' && message.msgContent.ext.data.atUsers.some(item => {
                        return item === this.userInfo.unionId || (item === '********-****-****-****-************' && message.toAccountId !== this.userInfo.unionId);
                    })
                })
                if(atMeMessageIndex > -1) {
                    atMeMessageIndex += start;
                    let messageListNode = this.$refs['message-list'];
                    if (!messageListNode) {
                        return
                    }
                    this.isAtMe = false;
                    this.hasNewMessage = false;
                    //跳到@我的消息位置之后提醒消失 此时如果停留在当前会话有新的@消息的时候 需要保证下一次查询是从该位置开始
                    this.atMeMsgStart = this.currentMessageList.length;
                    this.$nextTick(() => {
                        messageListNode.getElementsByClassName('message-wrapper')[atMeMessageIndex].scrollIntoView();
                    })
                }
            }
        },
        handleSendMessage(message) {
            message = convertMessage(message);
            if(message.type === TIM.TYPES.MSG_CUSTOM && !message.payload.data) {
                try {
                    message.payload.extension = JSON.parse(message.payload.extension);
                } catch {
                    message.payload.extension = message.payload.extension;
                }
                message.payload.ext = message.payload.extension;
            }
            if(message.type === TIM.TYPES.MSG_CUSTOM && message.payload.data) {
                try {
                    message.payload.ext = JSON.parse(message.payload.data);
                    message.payload.data = null;
                } catch(err) {
                    message.payload.ext = message.payload.data;
                    message.payload.data = null;
                }
            }
            let data = convertSdkMsgToLbdMsg(message);
            this.currentMessageList = [...this.currentMessageList, data];
            this.$nextTick(() => {
                this.scrollMessageListToButtom();
                this.isShowScrollButtomTips = false;
            });
        },
        handleSetMessageRead() {
            // tim.setMessageRead({
            //     conversationID: this.currentConversation.conversationID
            // }).then(res => {
            //     console.log('公司群消息已读');
            // }).catch(err => {
            //     console.log('setMessageReadError', err);
            // });
            openIM.markGroupMessageAsRead(this.currentConversation.conversationID).then(({ data })=>{
                console.log('data');
            }).catch(err => {
                console.log('setMessageReadError', err);
            })
        },
        checkIsAtMe() {
            if(this.unreadCount > 0) {
                let msgList = this.currentMessageList.slice(this.currentMessageList.length - this.unreadCount);
                msgList.forEach((message, index) => {
                    // @ 我 的消息
                    if(message.msgType === TIM.TYPES.MSG_CUSTOM && message.msgContent.ext.type === 'atMessage') {
                        let isAtMe = message.msgContent.ext.data.atUsers.some(item => {
                            return item === this.userInfo.unionId || (item === '********-****-****-****-************' && message.toAccountId !== this.userInfo.unionId);
                        })
                        if(isAtMe) {
                            if(!this.isAtMe) {
                                this.atMeMsgStart = this.currentMessageList.length - this.unreadCount + index;
                                this.isAtMe = true;
                            }
                        }
                    }
                })
            }
        },
        handleGroupTip() {
            let quitGroupTips = this.currentMessageList.filter(message => {
                return message.type === TIM.TYPES.MSG_GRP_TIP && (message.payload.operationType === TIM.TYPES.GRP_TIP_MBR_QUIT || message.payload.operationType === TIM.TYPES.GRP_TIP_MBR_KICKED_OUT) 
            });
            if (quitGroupTips.length > 0) {
                this.getMemberList();
            }
        },
        keepMessageListOnButtom() {
            let messageListNode = this.$refs['message-list']
            if (!messageListNode) {
                return
            }
            // 距离底部70px内强制滚到底部
            if (this.preScrollHeight - messageListNode.scrollTop - messageListNode.clientHeight < 70) {
                messageListNode.scrollTop = messageListNode.scrollHeight;
            }
            this.preScrollHeight = messageListNode.scrollHeight
        },
    }
}
</script>

<style lang="scss" scope>
    .firm-chat-room {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: #fff;
        .firm-title {
            background: #38bc9d;
            height: 40px;
            min-height: 40px;
            padding: 0 6px 0 12px;
            line-height: 40px;
            color: #fff;
            .firm-chat-avatar {
                width: 28px;
                height: 28px;
                margin-right: 2px;
                border: 1px solid $primary;
                border-radius: 50%;
            }
        }
        .firm-chat-list {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
            min-height: 292px;
            flex: 1;
            flex-grow: 1;
            overflow: hidden;
            .more {
                display: flex;
                justify-content: center;
                font-size: 12px;
                height: 30px;
            }
            .no-more {
                display: flex;
                justify-content: center;
                color: #a5b5c1;
                font-size: 12px;
                padding: 10px 10px;
            }
            .message-list {
                width: 100%;
                box-sizing: border-box;
                overflow-y: auto;
                padding: 0 12px;
            }
            .to-bottom-tip {
                position: absolute;
                cursor: pointer;
                padding: 5px;
                width: 120px;
                margin: auto;
                left: 0;
                right: 0;
                bottom: 5px;
                font-size: 12px;
                text-align: center;
                border-radius: 10px;
                border: #ccc 1px solid;
                background-color: #fff;
                color: $primary;
            }
            .at-me-tips, .unread-tips, .new-message-tips {
                position: absolute;
                cursor: pointer;
                color: $primary;
                width: 92px;
                height: 32px;
                line-height: 30px;
                right: 4px;
                bottom: 4px;
                font-size: 12px;
                text-align: center;
                border-radius: 4px;
                background-color: #fff;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
                .el-icon-close {
                    cursor: pointer;
                }
            }
            .unread-tips {
                width: 100px;
                top: 8px;
            }
        }
    }
</style>