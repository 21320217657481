var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "home-pannel-wrap",
      class: { "firm-chat-root-wrapper": _vm.isNewWorkbenchMain },
    },
    [
      _c(
        "div",
        { staticClass: "home-pannel-list" },
        _vm._l(_vm.cardItems, function (item, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    item.cnTitle !== "Csdn职通车" ||
                    (item.cnTitle == "Csdn职通车" && _vm.isCFUser),
                  expression:
                    "item.cnTitle !== 'Csdn职通车' || (item.cnTitle == 'Csdn职通车' && isCFUser)",
                },
              ],
              key: index,
              staticClass: "pannel-item",
              class: {
                "pannel-item-narrow":
                  _vm.isNewWorkbenchMain &&
                  ((_vm.isCFUser && index > 5) || (!_vm.isCFUser && index > 6)),
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleHomeCard(item)
                },
              },
            },
            [
              _c(
                "div",
                { class: item.enable ? "pannel-card" : "pannel-card disabled" },
                [
                  _c("div", { staticClass: "pannel-content" }, [
                    _c("div", {
                      class: `pannel-icon icon-${
                        item.enable ? item.icon : item.icon + " disabled"
                      }`,
                    }),
                    _c("div", { staticClass: "pannel-text" }, [
                      _c("p", [
                        _c("span", { staticClass: "cn-title" }, [
                          _vm._v(_vm._s(item.cnTitle)),
                        ]),
                        index > 4 ? _c("br") : _vm._e(),
                        _c("span", { staticClass: "en-title" }, [
                          _vm._v(_vm._s(item.enTitle)),
                        ]),
                      ]),
                      item.desc &&
                      (!_vm.isCFUser ||
                        (_vm.isCFUser && item.cnTitle !== "组织架构"))
                        ? _c("p", {
                            staticClass: "pannel-desc",
                            domProps: { innerHTML: _vm._s(item.desc) },
                          })
                        : _vm._e(),
                      item.cnTitle == "流程管理" && _vm.isCFUser
                        ? _c("p", { staticClass: "panel-point-tip" }, [
                            _vm._v("变更推荐状态每次可得5积分哦"),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  (_vm.isNewWorkbenchMain && index <= 3) ||
                  !_vm.isNewWorkbenchMain
                    ? _c("div", { staticClass: "pannel-notice" }, [
                        item.noticeData
                          ? _c(
                              "div",
                              { staticClass: "notice-wrap" },
                              [
                                _c(
                                  "el-carousel",
                                  {
                                    attrs: {
                                      height: "100%",
                                      autoplay: true,
                                      "indicator-position": "none",
                                      arrow: "never",
                                      direction: "vertical",
                                      loop: true,
                                    },
                                  },
                                  [
                                    _vm._l(item.noticeData, function (notice) {
                                      return [
                                        notice.totalStatisticsCount > 0
                                          ? _c(
                                              "el-carousel-item",
                                              { key: notice.reminderType },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "notice-item",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.noticeLink(
                                                          notice.reminderType,
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "icon-font icon-notice",
                                                    }),
                                                    _vm._v("您有"),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "orange-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            notice.totalStatisticsCount
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "位" +
                                                        _vm._s(
                                                          notice.reminderType ===
                                                            1
                                                            ? "候选人未处理"
                                                            : "候选人的推荐已接受"
                                                        ) +
                                                        "，"
                                                    ),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "orange-text",
                                                        attrs: {
                                                          href: "javascript:;",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            notice.reminderType ===
                                                              1
                                                              ? "处理看看"
                                                              : "去更新状态"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "吧\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  !item.enable
                    ? _c("span", { staticClass: "conner-mark" }, [
                        _vm._v("敬请期待"),
                      ])
                    : _vm._e(),
                  item.enable && item.isNew
                    ? _c(
                        "span",
                        { staticClass: "conner-mark conner-mark-new" },
                        [_vm._v("New")]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        }),
        0
      ),
      _vm.isNewWorkbenchMain
        ? _c(
            "div",
            { staticClass: "firm-chat-room-wrapper" },
            [_c("firm-chat-room", { ref: "firmChatRoom" })],
            1
          )
        : _vm._e(),
      _vm.isCFUser && _vm.jdtDialogVisible
        ? _c("image-dialog", {
            attrs: {
              width: "500px",
              height: "880px",
              imageSrc: _vm.jdtQcodeImage,
              imageAlt: "Csdn职通车二维码",
            },
            on: { hideDialog: _vm.hideJdtDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }