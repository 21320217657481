import { render, staticRenderFns } from "./firm-chat-room.vue?vue&type=template&id=7a28d8fc&"
import script from "./firm-chat-room.vue?vue&type=script&lang=js&"
export * from "./firm-chat-room.vue?vue&type=script&lang=js&"
import style0 from "./firm-chat-room.vue?vue&type=style&index=0&id=7a28d8fc&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7a28d8fc')) {
      api.createRecord('7a28d8fc', component.options)
    } else {
      api.reload('7a28d8fc', component.options)
    }
    module.hot.accept("./firm-chat-room.vue?vue&type=template&id=7a28d8fc&", function () {
      api.rerender('7a28d8fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/work-table-home/component/firm-chat-room.vue"
export default component.exports